@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  background: #fff;
  color: #444;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

a {
  color: #000000;
  transition: 0.5s !important;
}

a:hover,
a:active,
a:focus {
  color: #ff3d00;
  outline: none !important;
  text-decoration: none !important;
}

p {
  padding: 10px 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0 0 10px 0;
  padding: 0;
}

ul {
  padding: 0 !important;
  margin: 0;
  list-style: none;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.just-cont {
  justify-content: center;
}

.align-item {
  align-items: center;
}

.d-inline-b {
  display: inline-block;
}

.f-w-5 {
  font-weight: 500 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-60 {
  padding: 60px !important;
}

.p-8 {
  padding: 8px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-r-15 {
  padding-right: 15px;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.bg-grey {
  background: #f9f9f9;
}

.bg-grey1 {
  background: #f9f9f9;
}

.bg-white {
  background: #ffffff;
}

.bg-green {
  background-color: #219653;
}

.bg-yellow {
  background-color: #ffe927;
}

.bg-orange {
  background-color: #ff7800;
}

.bg-blue {
  background-color: #2f80ed;
}

.bg-black {
  background-color: #333333;
}

.bg-red {
  background-color: #ff3d00;
}

.text-red {
  color: #ff3d00 !important;
}

.text-black {
  color: #000;
}

.text-grey {
  color: rgba(0, 0, 0, 0.6);
}

.btn {
  border-radius: 4px;
  height: 45px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  padding: 0 25px;
}

.sign-in,
.upload-btn {
  border: 1px solid #cec9c9;
  color: #ff3d00;
}

.btn-dark {
  background: #ff3d00 !important;
  border: none;
  color: #fff;
}

.btn-outline-dark {
  border: 1px solid #cec9c9;
  color: #ff3d00;
}

.btn-link {
  color: rgba(0, 0, 0, 0.6);
}

.btn-link:hover {
  color: #ff3d00;
}

.btn.focus,
.btn:focus {
  box-shadow: inherit !important;
}

.form-control:focus {
  border: #ff3d00;
  box-shadow: 0 0 0 0.2rem rgb(255 61 0 / 25%) !important;
}

.w-100 {
  width: 100%;
}

.w-700 {
  max-width: 700px !important;
}

.w-38 {
  width: 38% !important;
}

.w-57 {
  width: 57% !important;
}

.w-420 {
  max-width: 420px !important;
}

.w-280 {
  max-width: 280px !important;
}

.w-80 {
  width: 80%;
}

.text-tr {
  text-transform: uppercase;
}

.text-cap {
  text-transform: capitalize !important;
}

.text-tran {
  text-transform: inherit !important;
}

.b-0 {
  border: transparent !important;
}

.b-1 {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.b-t {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.b-r {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.b-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.b-t-15 {
  border-top: 15px solid #f9f9f9;
}

.pot-rel {
  position: relative;
}

.pot-abl {
  position: absolute;
}

.rgt-0 {
  right: 0;
}

.top-15 {
  top: -15px;
}

input[type="radio"] {
  border: 0px;
  width: 1em;
  height: 1em;
}

.p-f-10 {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.p-xs-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.p-x-small {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}

.p-small {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.p-large {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.p-x-large {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.p-f-24 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.18px;
}

.p-f-25 {
  font-size: 25px;
  line-height: 31px;
  font-weight: 500;
}

.p-f-34 {
  font-size: 34px;
  line-height: 42px;
}

.small,
small {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 4px 10px;
}

.op-6 {
  opacity: 0.6;
}

.op-8 {
  opacity: 0.8;
}
/* 
.container {
  padding-left: 0;
  padding-right: 0;
} */

.line {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 25px;
  text-align: center;
}

.line span {
  font-size: 15px;
  text-align: center;
  position: relative;
  top: 10px;
  padding: 0 15px;
  display: inline-block;
  background: white;
}

/*Header*/
#header {
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 2px 25px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#header.header-scrolled {
  height: 60px;
  padding: 10px 0;
}

/*Navigation Menu*/
.navbar-custom {
  display: flex;
  align-items: center;
}

.navbar-custom .logo {
  margin-left: 0;
  width: 16%;
}

.navbar-custom .logo a {
  display: block;
}

.navbar-custom .btn {
  width: 116px;
}

.main-nav,
.main-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav {
  width: 84%;
}

.main-nav .nav-custom {
  display: flex;
}

.main-nav ul {
  display: flex;
  align-items: center;
}

.main-nav .nav-custom .nav-btn {
}

.main-nav a {
  display: block;
  position: relative;
  color: #000;
  padding: 10px 25px;
  transition: 0.3s;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.main-nav a:hover,
.main-nav .active > a,
.main-nav li:hover > a,
.main-nav li:hover > a i {
  color: #ff3d00;
  text-decoration: none;
}

.mob-btn .btn {
  border: transparent;
  text-align: right;
}

/* Mobile Navigation */
.mobile-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  left: -80%;
  width: 80%;
  background: rgb(255 255 255 / 100%);
  transition: 0.4s;
  padding: 20px;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #000;
  padding: 12px 0;
  font-weight: 500;
}

.mobile-nav .nav-custom .nav-btn .btn {
  width: 100%;
}

.mobile-nav .nav-custom .nav-btn .btn.sign-in {
  margin-left: 0 !important;
  margin-top: 15px;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #ff3d00;
  text-decoration: none;
}

.mobile-nav-toggle {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  margin: 21px 10px 18px;
  color: #000000;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgb(0 0 0 / 40%);
  overflow: hidden;
  display: none;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #000;
  display: none;
}

.main-nav a.mobile-logo {
  display: none;
}

.mob-btn .btn {
  display: none;
}

/*Footer*/
.footer {
  background: #f9f9f9;
  text-align: center;
  padding: 40px 0;
  transition: all 0.5s;
}

.footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer ul li {
  padding: 10px 30px;
  width: inherit;
}

.footer li a {
  color: #000;
}

.footer .foot-logo {
  display: block;
  padding: 10px 0 30px 0;
}

.footer .copyright {
  font-size: 12px;
  text-transform: capitalize;
  line-height: 14px;
  opacity: 38%;
}

@media (max-width: 574px) {
}

@media (max-width: 767px) {
  #header {
    padding: 10px 0;
  }

  .navbar-custom .logo {
    margin-left: 40px;
    width: inherit;
  }

  .main-nav a.mobile-logo {
    display: block;
  }

  .mob-btn .btn {
    display: block;
  }

  .footer ul {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
}
.active-appt {
  background-color: rgba(255, 61, 0, 0.08);
}
@media (max-width: 991px) {
  .navbar-custom .logo {
    margin-left: 40px;
    width: inherit;
  }

  .mob-btn .btn {
    display: block;
    color: #000;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-sb::-webkit-scrollbar {
  display: none;
}

.chatsection::-webkit-scrollbar {
  width: 8px;
}
.chatsection::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
.chatsection::-webkit-scrollbar-thumb {
  background: #D9D9D9; 
  border-radius: 15px;
}


/* Hide scrollbar for IE, Edge and Firefox */
.no-sb {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.bullets ul {
  padding: inherit !important;
  list-style: disc;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.38);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.38);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.38);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.38);
}

.text-fade {
  color: rgba(0, 0, 0, 0.6);
}
html {
  max-width: 100%;
  overflow-x: hidden;
}
