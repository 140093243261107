.appTM .navbar-custom .logo .logoV1{display: none;}
.appTM .navbar-custom .logo .logoV2{display: block;height: auto;width: 100%;margin: 0 auto;  }
.appTM .new-nav{display: flex; justify-content: center ;}
.appTM .new-nav #header{width: max(10%,120px);box-shadow: inherit;text-align: center;padding:10px 0; min-height: 100vh; border-right: 1px solid rgba(0, 0, 0, 0.12);}
.main-nav{width: 100%;}
.navbar-custom{display: inherit;}
.navbar-custom .logo{
  width: 100%;
  margin-right: 0;
}
.appTM .navbar-custom ul{margin-top: 5px;}
.appTM .navbar-custom li{display: block; width: 100%;}
.appTM .navbar-custom li span{display: block; font-size: 14px; line-height: 19px; font-weight: 400;}
.appTM .navbar-custom ul li a{display: block; padding: 13px 8px;}
.appTM .navbar-custom ul li a i{font-size: 24px; color: rgba(0, 0, 0, 0.38); padding-bottom: 7px;}
.appTM .navbar-custom ul li:hover>a i{color: #FF3D00 !important;}
.appTM .navbar-custom ul li.active>a i{color: #FF3D00 !important;}
.fndAppt .search{display: block;padding: 10px 10px 0 10px;}
.fndAppt .search .search-txt{position: relative; width: 58%; display: inline-block;}
.fndAppt .search .search-btn{ width: 40%; display: inline-block;}
.fndAppt .search input[type="text"]{padding: 4px 4px 4px 18px;border-radius: 30px;height: 53px;color: rgba(0, 0, 0, 0.38);box-sizing: border-box;}
.fndAppt .search .btn{height: 50px; border-radius: 30px; width: 100%;}
.fndAppt .search i{position: absolute;top: 17px;left: 16px;}
.fndAppt .search-mob .form-group{margin-bottom: 10px;}

@media (max-width: 574px) {
   
}


@media (max-width: 767px) {

  
}

@media (max-width: 991px) {
  /* .appTM .new-nav #header{width: 0; padding: 0;} */
  .appTM .new-nav .sec-Cont{width: 100%;padding: 0 15px;}
  .appTM .navbar-custom .logo .logoV1{display: block;}
  .appTM .navbar-custom .logo .logoV2{display: none;}
  .appTM .mobile-nav-toggle{position: absolute;top: 5px;background: TRANSPARENT;}
  .appTM .mobile-nav  ul li a i{padding-right: 15px;}
  .appTM .new-nav{display: flex;}
  .appTM .new-nav #header{min-height: 100%;}
  .fndAppt .new-nav #header{padding: 12px 0;border-right: 0;}
  /* .fndAppt .search-mob .search-txt{position: absolute;top: -52px;right: 25px;width: 80%;} */
  .fndAppt .navbar-custom .search-txt{position: relative;}
  .fndAppt .navbar-custom .search-txt i{position: absolute;}
  /* .fndAppt .search{display: none;} */
  .fndAppt .search-mob{padding: 0;display: block;clear:right}
  .fndAppt .search-mob .btn{display: none;}
  .fndAppt .search-mob .form-group{margin-bottom: 0;}
  .fndAppt .search input[type="text"]{height: 45px;}
  .fndAppt .search i{top: 13px;}
  
  
}




