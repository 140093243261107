@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
html {
  scroll-behavior: smooth;
}
body {
  background: #fff;
  color: #444;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

a {
  color: #000000;
  transition: 0.5s !important;
}

a:hover,
a:active,
a:focus {
  color: #ff3d00;
  outline: none !important;
  text-decoration: none !important;
}

p {
  padding: 10px 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0 0 10px 0;
  padding: 0;
}

ul {
  padding: 0 !important;
  margin: 0;
  list-style: none;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.just-cont {
  justify-content: center;
}

.align-item {
  align-items: center;
}

.d-inline-b {
  display: inline-block;
}

.f-w-5 {
  font-weight: 500 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-60 {
  padding: 60px !important;
}

.p-8 {
  padding: 8px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-r-15 {
  padding-right: 15px;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.bg-grey {
  background: #f9f9f9;
}

.bg-grey1 {
  background: #f9f9f9;
}

.bg-white {
  background: #ffffff;
}

.bg-green {
  background-color: #219653;
}

.bg-yellow {
  background-color: #ffe927;
}

.bg-orange {
  background-color: #ff7800;
}

.bg-blue {
  background-color: #2f80ed;
}

.bg-black {
  background-color: #333333;
}

.bg-red {
  background-color: #ff3d00;
}

.text-red {
  color: #ff3d00 !important;
}

.text-black {
  color: #000;
}

.text-grey {
  color: rgba(0, 0, 0, 0.6);
}

.btn {
  border-radius: 4px;
  height: 45px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  padding: 0 25px;
}

.sign-in,
.upload-btn {
  border: 1px solid #cec9c9;
  color: #ff3d00;
}

.btn-dark {
  background: #ff3d00 !important;
  border: none;
  color: #fff;
}

.btn-outline-dark {
  border: 1px solid #cec9c9;
  color: #ff3d00;
}

.btn-link {
  color: rgba(0, 0, 0, 0.6);
}

.btn-link:hover {
  color: #ff3d00;
}

.btn.focus,
.btn:focus {
  box-shadow: inherit !important;
}

.form-control:focus {
  border: #ff3d00;
  box-shadow: 0 0 0 0.2rem rgb(255 61 0 / 25%) !important;
}

.w-100 {
  width: 100%;
}

.w-700 {
  max-width: 700px !important;
}

.w-38 {
  width: 38% !important;
}

.w-57 {
  width: 57% !important;
}

.w-420 {
  max-width: 420px !important;
}

.w-280 {
  max-width: 280px !important;
}

.w-80 {
  width: 80%;
}

.text-tr {
  text-transform: uppercase;
}

.text-cap {
  text-transform: capitalize !important;
}

.text-tran {
  text-transform: inherit !important;
}

.b-0 {
  border: transparent !important;
}

.b-1 {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.b-t {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.b-r {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.b-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.b-t-15 {
  border-top: 15px solid #f9f9f9;
}

.pot-rel {
  position: relative;
}

.pot-abl {
  position: absolute;
}

.rgt-0 {
  right: 0;
}

.top-15 {
  top: -15px;
}

input[type="radio"] {
  border: 0px;
  width: 1em;
  height: 1em;
}

.p-f-10 {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.p-xs-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.p-x-small {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}

.p-small {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.p-large {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.p-x-large {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.p-f-24 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.18px;
}

.p-f-25 {
  font-size: 25px;
  line-height: 31px;
  font-weight: 500;
}

.p-f-34 {
  font-size: 34px;
  line-height: 42px;
}

.small,
small {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 4px 10px;
}

.op-6 {
  opacity: 0.6;
}

.op-8 {
  opacity: 0.8;
}
/* 
.container {
  padding-left: 0;
  padding-right: 0;
} */

.line {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 25px;
  text-align: center;
}

.line span {
  font-size: 15px;
  text-align: center;
  position: relative;
  top: 10px;
  padding: 0 15px;
  display: inline-block;
  background: white;
}

/*Header*/
#header {
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 2px 25px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#header.header-scrolled {
  height: 60px;
  padding: 10px 0;
}

/*Navigation Menu*/
.navbar-custom {
  display: flex;
  align-items: center;
}

.navbar-custom .logo {
  margin-left: 0;
  width: 16%;
}

.navbar-custom .logo a {
  display: block;
}

.navbar-custom .btn {
  width: 116px;
}

.main-nav,
.main-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav {
  width: 84%;
}

.main-nav .nav-custom {
  display: flex;
}

.main-nav ul {
  display: flex;
  align-items: center;
}

.main-nav .nav-custom .nav-btn {
}

.main-nav a {
  display: block;
  position: relative;
  color: #000;
  padding: 10px 25px;
  transition: 0.3s;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.main-nav a:hover,
.main-nav .active > a,
.main-nav li:hover > a,
.main-nav li:hover > a i {
  color: #ff3d00;
  text-decoration: none;
}

.mob-btn .btn {
  border: transparent;
  text-align: right;
}

/* Mobile Navigation */
.mobile-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  left: -80%;
  width: 80%;
  background: rgb(255 255 255 / 100%);
  transition: 0.4s;
  padding: 20px;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #000;
  padding: 12px 0;
  font-weight: 500;
}

.mobile-nav .nav-custom .nav-btn .btn {
  width: 100%;
}

.mobile-nav .nav-custom .nav-btn .btn.sign-in {
  margin-left: 0 !important;
  margin-top: 15px;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #ff3d00;
  text-decoration: none;
}

.mobile-nav-toggle {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  margin: 21px 10px 18px;
  color: #000000;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgb(0 0 0 / 40%);
  overflow: hidden;
  display: none;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #000;
  display: none;
}

.main-nav a.mobile-logo {
  display: none;
}

.mob-btn .btn {
  display: none;
}

/*Footer*/
.footer {
  background: #f9f9f9;
  text-align: center;
  padding: 40px 0;
  transition: all 0.5s;
}

.footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer ul li {
  padding: 10px 30px;
  width: inherit;
}

.footer li a {
  color: #000;
}

.footer .foot-logo {
  display: block;
  padding: 10px 0 30px 0;
}

.footer .copyright {
  font-size: 12px;
  text-transform: capitalize;
  line-height: 14px;
  opacity: 38%;
}

@media (max-width: 574px) {
}

@media (max-width: 767px) {
  #header {
    padding: 10px 0;
  }

  .navbar-custom .logo {
    margin-left: 40px;
    width: inherit;
  }

  .main-nav a.mobile-logo {
    display: block;
  }

  .mob-btn .btn {
    display: block;
  }

  .footer ul {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
}
.active-appt {
  background-color: rgba(255, 61, 0, 0.08);
}
@media (max-width: 991px) {
  .navbar-custom .logo {
    margin-left: 40px;
    width: inherit;
  }

  .mob-btn .btn {
    display: block;
    color: #000;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-sb::-webkit-scrollbar {
  display: none;
}

.chatsection::-webkit-scrollbar {
  width: 8px;
}
.chatsection::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
.chatsection::-webkit-scrollbar-thumb {
  background: #D9D9D9; 
  border-radius: 15px;
}


/* Hide scrollbar for IE, Edge and Firefox */
.no-sb {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.bullets ul {
  padding: inherit !important;
  list-style: disc;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.38);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.38);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.38);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.38);
}

.text-fade {
  color: rgba(0, 0, 0, 0.6);
}
html {
  max-width: 100%;
  overflow-x: hidden;
}

/*nero Section*/
.nero {width: 100%;position: relative;background: url(/static/media/hero-banner.99faa80f.png) center bottom no-repeat;background-size: cover;padding: 140px 0 60px 0;background-position: top; transition: all 0.5s;}
.nero .nero-info {width: 70%;}
.nero .nero-info h1 {color: #fff;margin-bottom: 15px;font-size: 62px;font-weight: 700; line-height: 73px;}
.nero .nero-info p{color: #fff;margin-bottom: 8px;}
.nero .nero-info .btn-get-started {font-family: "Roboto", sans-serif;font-size: 14px;font-weight: 600;letter-spacing: 1px;  display: inline-block;padding: 10px 32px;border-radius: 50px;transition: 0.5s;margin: 0 20px 20px 0;color: #fff;}
.nero .nero-info .btn-get-started {background: #007bff;border: 2px solid #007bff;color: #fff;}
.nero .nero-info .btn-get-started:hover {background: none;border-color: #fff;color: #fff;}

/*Sections*/
section { overflow: hidden; transition: all 0.5s;}
.section-header h3 {font-size: 36px;color: #283d50;text-align: center;font-weight: 500;position: relative;}
.section-header p {text-align: center;margin: auto;font-size: 15px;padding-bottom: 60px;color: #556877;width: 50%;}

/* Content Section*/
.sec-Cont {padding: 40px 0 40px 0; transition: all 0.5s;}
.sec-Cont .card-Cont{display: flex;justify-content: center;align-items: center;padding: 0 30px;}
.sec-Cont .card-Cont .content {padding: 8px 0;}
.sec-Cont .card-Cont .content .title {color: #333;font-weight: 500;font-size: 34px;line-height: 42px;margin-bottom: 7px;}
.sec-Cont .card-Cont .content p {line-height: 20px;margin-bottom: 10px;padding: 5px 0;}
.sec-Cont .card-Cont .content p:last-child {margin-bottom: 0;}
.sec-Cont .cont-extra {padding-top: 60px;}
.sec-Cont .cont-extra h4 {font-weight: 600;font-size: 24px;}

/*get-started*/
.get-started {padding: 60px 0;background: url(/static/media/get-started.5c4c2a5a.png) center bottom no-repeat;background-size: cover;background-position: center; transition: all 0.5s;}
.get-started .card-Cont .content .title {color: #333;font-weight: 700;font-size: 34px;line-height: 42px;margin-bottom: 20px;}

/* Contact Section*/
.contact .cont-form{background: #fff;padding: 20px 50px ;max-width: 630px;margin: 0 auto;border-radius: 5px; transition: all 0.5s;}
.contact h3 {font-size: 28px;line-height: 33px;font-weight: 700;text-align: center;margin-bottom: 20px;}
.contact .form input, .contact .form textarea {border-radius: 4px;box-shadow: none;font-size: 16px;line-height: 24px;letter-spacing: 1px;}
.contact .form input {padding: 20px 15px;height: 54px;}
.contact .form textarea {padding: 12px 15px;}
.contact .form .btn{padding: 0 40px;}
.primary-label{color:#FF3D00;}

@media (max-width: 574px) {
    .nero {padding: 100px 0 20px 0;}
}


@media (max-width: 767px) {
  .bg-grey{background: #ffffff;}
  .nero .nero-info {width: 100%;text-align: left;padding: 18px 15px;}
  .nero .nero-info h1 {font-size: 30px;line-height: 35px;margin-bottom: 10px;}
  .nero .nero-info p{font-size: 18px;}
  .sec-Cont .card-Cont{padding: 0 10px;}
  .section-header p {width: 100%;}
  .sec-Cont{padding: 40px 15px 0 15px;}
  .sec-Cont .card-Cont{padding: 0 10px;}
  .sec-Cont .card-Cont .content .title{font-size: 24px; line-height: 28px; margin-bottom: 0;}
  .get-started {margin-top: 40px;}
  .get-started .card-Cont .content .title {font-size: 28px;line-height: 34px;margin-bottom: 20px;}
  .get-started .card-Cont .content .title span{color: #FF3D00;}
  .contact .cont-form{padding: 0 8px 35px 8px;}
  
}

@media (max-width: 991px) {
  .navbar-custom .logo {margin-left: 40px;width: inherit;}

}






.appTM .navbar-custom .logo .logoV1{display: none;}
.appTM .navbar-custom .logo .logoV2{display: block;height: auto;width: 100%;margin: 0 auto;  }
.appTM .new-nav{display: flex; justify-content: center ;}
.appTM .new-nav #header{width: max(10%,120px);box-shadow: inherit;text-align: center;padding:10px 0; min-height: 100vh; border-right: 1px solid rgba(0, 0, 0, 0.12);}
.main-nav{width: 100%;}
.navbar-custom{display: inherit;}
.navbar-custom .logo{
  width: 100%;
  margin-right: 0;
}
.appTM .navbar-custom ul{margin-top: 5px;}
.appTM .navbar-custom li{display: block; width: 100%;}
.appTM .navbar-custom li span{display: block; font-size: 14px; line-height: 19px; font-weight: 400;}
.appTM .navbar-custom ul li a{display: block; padding: 13px 8px;}
.appTM .navbar-custom ul li a i{font-size: 24px; color: rgba(0, 0, 0, 0.38); padding-bottom: 7px;}
.appTM .navbar-custom ul li:hover>a i{color: #FF3D00 !important;}
.appTM .navbar-custom ul li.active>a i{color: #FF3D00 !important;}
.fndAppt .search{display: block;padding: 10px 10px 0 10px;}
.fndAppt .search .search-txt{position: relative; width: 58%; display: inline-block;}
.fndAppt .search .search-btn{ width: 40%; display: inline-block;}
.fndAppt .search input[type="text"]{padding: 4px 4px 4px 18px;border-radius: 30px;height: 53px;color: rgba(0, 0, 0, 0.38);box-sizing: border-box;}
.fndAppt .search .btn{height: 50px; border-radius: 30px; width: 100%;}
.fndAppt .search i{position: absolute;top: 17px;left: 16px;}
.fndAppt .search-mob .form-group{margin-bottom: 10px;}

@media (max-width: 574px) {
   
}


@media (max-width: 767px) {

  
}

@media (max-width: 991px) {
  /* .appTM .new-nav #header{width: 0; padding: 0;} */
  .appTM .new-nav .sec-Cont{width: 100%;padding: 0 15px;}
  .appTM .navbar-custom .logo .logoV1{display: block;}
  .appTM .navbar-custom .logo .logoV2{display: none;}
  .appTM .mobile-nav-toggle{position: absolute;top: 5px;background: TRANSPARENT;}
  .appTM .mobile-nav  ul li a i{padding-right: 15px;}
  .appTM .new-nav{display: flex;}
  .appTM .new-nav #header{min-height: 100%;}
  .fndAppt .new-nav #header{padding: 12px 0;border-right: 0;}
  /* .fndAppt .search-mob .search-txt{position: absolute;top: -52px;right: 25px;width: 80%;} */
  .fndAppt .navbar-custom .search-txt{position: relative;}
  .fndAppt .navbar-custom .search-txt i{position: absolute;}
  /* .fndAppt .search{display: none;} */
  .fndAppt .search-mob{padding: 0;display: block;clear:right}
  .fndAppt .search-mob .btn{display: none;}
  .fndAppt .search-mob .form-group{margin-bottom: 0;}
  .fndAppt .search input[type="text"]{height: 45px;}
  .fndAppt .search i{top: 13px;}
  
  
}





.accInfo .new-nav .sec-Cont {
  width: 90%;
}

.accInfo .new-nav .sec-Cont .myAcc {
  max-width: 828px;
  margin: 30px auto;
  background: #fff;
  border-radius: 5px;
  display: flex;
  height: 100vh;
}

.accInfo .new-nav .sec-Cont .myAcc .content {
  width: 50%;
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .content .tab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .tab ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .content .tab ul li {
  width: 100%;
  text-align: center;
  padding: 12px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
}

.accInfo .new-nav .sec-Cont .myAcc .content .tab ul li.active {
  color: #FF3D00;
  border-bottom: 2px solid #FF3D00;
}

.accInfo .new-nav .sec-Cont .myAcc .content .search-txt {
  padding: 0px 15px 10px 15px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.accInfo .new-nav .sec-Cont .myAcc .content .search-txt input[type="text"] {
  padding-left: 40px;
  height: 48px;
  font-size: 14px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .search-txt i {
  position: absolute;
  top: 14px;
  left: 25px;
  color: rgba(0, 0, 0, 0.6);
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .naME {
  width: 25%;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .naME b {
  background: #FBE9E7;
  color: #FF3D00;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  border-radius: 50%;
  padding: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .deTail {
  width: 80%;
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .deTail h4 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .deTail span {
  position: absolute;
  top: 0;
  right: 5px;
  color: rgba(0, 0, 0, 0.38);
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
}

.accInfo .new-nav .sec-Cont .myAcc .content .newMass {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .newMass button {
  border-radius: 28px;
  height: 35px;
  padding: 7px 15px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col {
  display: block;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col {
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 20px;
  display: block;
  width: 100%;
  text-align: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .title .back-icon {
  padding-right: 20px;
  display: none;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .title .more-icon {
  display: none;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massList {
  padding: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massList .massDet {
  background: #F2F2F2;
  border-radius: 4px;
  width: 70%;
  padding: 20px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massList .mechat{margin-top: 15px; margin-left: auto; background: #FBE9E7; color: #000;}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 8px 8px 8px;
  background: #fff;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot ul li {
  width: 50%;
  padding: 8px 4px 8px 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 9px 5px;
  border-radius: 4px;
  color: #FF3D00;
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  box-sizing: border-box;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType div:first-child i {
  font-size: 22px;
  font-weight: 700;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType div:first-child {
  width: 10%;
  text-align: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType div:last-child {
  width: 90%;
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType div:last-child i {
  position: absolute;
  right: 15px;
  top: 10px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .pfile {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  margin: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .content .pfile .naME {
  width: 30%;
  margin-right: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .pfile .naME b {
  background: #FBE9E7;
  color: #FF3D00;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  border-radius: 50%;
  padding: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li {
  display: block;
  width: 100%;
  padding: 12px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 400;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li a i {
  padding-right: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li:after {
  content: " ";
  border: solid #000000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 20px;
  right: 21px;
  transform: rotate(-45deg);
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li.active {
  background: #FBE9E7;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .pfile {
  padding: 25px;
  margin-right: 0;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .pfile .naME {
  position: relative;
  display: block;
  margin: 0;
  width: 30%;
  text-align: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .pfile .naME b {
  font-size: 48px;
}

.edit {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #FF3D00;
  border-radius: 25px;
  padding: 5px;
  width: 35px;
  height: 35px;
  text-align: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .accDet li {
  padding: 15px 25px 15px 40px !important;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .accDet li span {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .accDet ul li:after {
  top: 35px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .accDet li .agn-stats {
  background: #005338;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  /* text-align: right; */
  position: absolute;
  right: 45px;
  top: 25px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accDet ul li a i {
  padding-right: 5px;
}


.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardDet {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardDet .cardNo {
  width: 90%;
  margin-left: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardDet .cardNo span {
  display: block;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardEdit {
  padding: 30px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardEdit .form-control {
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 54px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-left: 20px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardEdit .form-group {
  margin-bottom: 25px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardEdit .form-group .edit-label {
  position: absolute;
  top: -11px;
  left: 22px;
  font-size: 14px;
  padding: 0 6px;
  background: #fff;
  text-transform: capitalize;
  opacity: 0.6;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .notiList ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 15px 20px;
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .notiList ul li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .notiList ul li span {
  position: absolute;
  right: 20px;
  top: 12px;
}


.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 17px;
  border-radius: 20px;
  background: #dce4e2;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
}

.switch::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0px;
  width: 25px;
  height: 25px;
  background: #acb9b5;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

input:checked+.switch {
  background: #54bf9c;
}

input:checked+.switch::before {
  left: 25px;
  background: #005338;
}

input:checked+.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .searchCode ul {
  display: flex;
  padding: 0 20px;
  flex-wrap: wrap;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .searchCode ul li {
  /* width: 100%; */
  margin-right: 10px;
  margin-bottom: 16px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .searchCode ul li a {
  background: rgba(33, 33, 33, 0.08);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding: 6px 15px;
  border-radius: 15px;
  opacity: 0.6;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .bAgent ul li a {
  background: #FBE9E7;
}

.review .sec-Cont .overley {
  background: #000;
  opacity: 0.4;
  display: block;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.review .sec-Cont .form {
  max-width: 560px;
  margin: 0 auto;
}

.review .sec-Cont .form-content {
  padding: 20px 0;
}

.review .sec-Cont .form-content .title {
  position: relative;
  margin-bottom: 20px;
  padding: 10px 30px;
}

.review .sec-Cont .form-content .title span {
  position: absolute;
  right: 30px;
}

.review .sec-Cont .form-content .pfile {
  padding: 15px 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review .sec-Cont .form-content .pfile .naME {
  width: 30%;
  margin-right: 15px;
}

.review .sec-Cont .form-content .pfile b {
  background: #FBE9E7;
  color: #FF3D00;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  border-radius: 50%;
  padding: 15px;
}

.review .sec-Cont .form-content .reViewSec {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin: 20px 30px;
}

.review .sec-Cont .form-content .reViewSec div {
  display: flex;
  align-items: center;
}

.review .sec-Cont .form-content .reViewSec div .name {
  margin-left: 15px;
  font-weight: 500;
}

.review .sec-Cont .form-content .reViewSec div .reStar {
  margin-left: auto;
}

@media (max-width: 767px) {}

@media (max-width: 991px) {
  .accInfo .new-nav .sec-Cont {
    width: 100%;
    padding: 0;
  }

  .accInfo .new-nav .sec-Cont .myAcc {
    display: inherit;
  }

  .accInfo .new-nav .sec-Cont .myAcc .content {
    width: 100%;
    height: 100vh;
  }

  .accInfo .new-nav .sec-Cont .myAcc .rgt-col {
    display: none;
  }

  .accInfo .new-nav .sec-Cont .myAcc .rgt-col .title {
    display: inline-flex;
  }

  .accInfo .new-nav .sec-Cont .myAcc .rgt-col .title .back-icon {
    display: block;
  }

  .accInfo .new-nav .sec-Cont .myAcc .rgt-col .title .more-icon {
    display: block;
    position: absolute;
    right: 25px;
  }

  .review .sec-Cont .form-content {
    padding: 0;
  }

  .review .sec-Cont .form-content .title {
    padding: 12px 15px;
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .review .sec-Cont .form-content .pfile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .review .sec-Cont .form-content .reViewSec {
    margin: 20px;
  }

  .review .sec-Cont .overley {
    background: #fff;
  }

  .mpage {
    display: block !important;
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 99999;
  }

  .accInfo .new-nav .sec-Cont .myAcc .border-right {
    border-right: transparent !important;
  }


}

.ScrollStyle
{
    max-height: 570px;
    overflow-y: scroll;
}
.bg-grey {
  background: #fff;
}

.form-check-label {
  align-items: center;
  justify-content: center;
  display: flex;
}

.mobile-nav-toggle i {
  display: none;
}

.form-control {
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 54px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-left: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

/*Sign up form*/
.cross {
  background: #fff;
}

.form {
  max-width: 506px;
  margin: 50px auto;
}

.form form {
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.form .logo {
  width: 100%;
  padding: 40px 40px 0 40px;
}

.form .form-content {
  padding: 20px 40px 40px 40px;
}

.form .form-content h4 {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 28px;
  font-weight: 500;
}

.form .form-content .form-group {
  margin-bottom: 20px;
  transition: all 0.5s;
  position: relative;
}

.form .form-content .radio-btn {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 30px 15px 30px 25px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.form .form-content .form-check-input {
  position: inherit;
  margin-top: 0;
  margin-right: 15px;
  margin-left: 0;
}

.form .form-content .form-group a {
  color: #ff3d00;
  display: block;
  margin-top: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 16px;
}

.form .form-content .form-group i {
  position: absolute;
  top: 17px;
  right: 30px;
  font-weight: 600;
}

.form .form-content .form-group .term-Con {
  text-transform: capitalize;
  color: #000;
}

.form .form-content .form-group label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
}

.form .form-content .form-group input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin-right: 8px;
  border: 2px solid #000;
}

.form .form-content .form-check {
  padding-left: 0;
}

.form .form-content .form-check label {
  justify-content: flex-start;
  /* text-transform: capitalize; */
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.other-btn {
  padding: 8px 0;
}

.other-btn .btn {
  text-transform: lowercase;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
}

.form-layout .form-content .ag-name {
  background: #f2f2f2;
  padding: 6px 8px;
  border-radius: 5px;
}

.form-layout .form-content span b {
  display: block;
  text-transform: capitalize;
}

select {
  background: #f0f3fb;
  border: 1px solid #f0f3fb;
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  color: #3f3f3f;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:after {
  font-family: FontAwesome;
  content: "\f0dd";
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 30px;
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
}

select::-ms-expand {
  display: none;
}

.passRest .form {
  text-align: center;
  max-width: 700px;
}

.passRest .form .form-content h4 {
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.18px;
}

.appLive {
  padding-top: 40px;
}

.appLive .btn {
  width: 100%;
}

.edit .form .form-group .edit-label {
  position: absolute;
  top: -11px;
  left: 22px;
  font-size: 14px;
  padding: 0 6px;
  background: #fff;
  text-transform: capitalize;
}

.form .form-content .counter {
  height: 80px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
}

.form .form-content .counter span:first-child {
  width: 20%;
  display: inline-block;
}

.form .form-content .counter span:last-child {
  width: 80%;
  display: inline-block;
}

.form .form-content .counter i {
  left: 20px;
  top: 20px;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.38);
  font-weight: 400;
  position: inherit;
}

.form .form-content .counter input {
  width: 100%;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  padding: 13px 11px 13px 19px;
  font-size: 22px;
  line-height: 26px;
  font-family: "Roboto", sans-serif !important;
  color: rgba(0, 0, 0, 0.38);
}
.form .form-content .counter input:focus,
.form .form-content .counter input:active {
  border: #ff3d00;
  box-shadow: 0 0 0 0.2rem rgb(255 61 0 / 25%) !important;
  border: none;
}

.fillter .form .form-content {
  padding: 25px 25px 60px;
}

.fillter .form .form-content .head {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.fillter .form .form-content .head .title {
  width: 90%;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: capitalize;
  text-align: center;
  font-weight: 500;
}

.fillter .form .form-content .head .cross {
  width: 10%;
}

.fillter .form .form-content .head .cross button {
  border: none;
  background: transparent;
}

.fillter .form .form-content .head .cross button i {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.fillter .form .form-content .fil-tab {
  width: 100%;
  margin-bottom: 20px;
}

.fillter .form .form-content .fil-tab ul {
  display: flex;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.fil-active {
  background-color: #fbe9e7;
  color: #ff3d00 !important;
}
.fil-active > a {
  color: #ff3d00 !important;
}

.fillter .form .form-content .fil-tab ul li {
  padding: 10px 6px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fillter .form .form-content .fil-tab ul li a {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.fillter .form .form-content .fil-tab ul li:nth-child(1) {
  width: 20%;
}

.fillter .form .form-content .fil-tab ul li:nth-child(2) {
  width: 40%;
}

.fillter .form .form-content .fil-tab ul li:nth-child(3) {
  border-right: none;
  width: 40%;
}

.fillter .form .form-content .fil-tab ul li:hover {
  background: #fbe9e7;
}

.fillter .form .form-content .fil-tab ul li:hover a {
  color: #ff3d00;
}

.fillter .form .form-content .form-group {
  margin-bottom: 10px;
}

.fillter .form .form-content .form-group .form-control {
  height: 40px;
  font-size: 14px;
  /* padding-left: 8px; */
  line-height: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding: 7px 0 7px 8px;
}

.fillter .form .form-content .form-group .select-wrapper:after {
  top: 4px;
  right: 15px;
}

.fillter .form .form-content .it-list {
  margin-bottom: 20px;
}

.fillter .form .form-content .it-list ul {
  display: flex;
  flex-wrap: wrap;
}

.fillter .form .form-content .it-list ul li {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 9px;
  margin: 5px 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 4px;
}

.fillter .form .form-content .it-list ul li:hover {
  background: #fbe9e7;
  color: #ff3d00;
  border: 1px solid #ff3d00;
}

.fillter .form .bot-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0px;
  background: #fff;
  width: 420px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.fillter .form .bot-btn-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0px;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
}

.fillter .form .bot-btn button {
  width: 100%;
}

.fillter .form .bot-btn-mobile button {
  width: 100%;
}

.div-radio:hover {
  cursor: pointer;
}

.popup .form-content h4 {
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 24px;
  font-weight: 500;
}

.popup .form-content {
  padding: 15px !important;
}

/*--Button sign in with---*/
.oth-login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.oth-login a {
  width: 100%;
  margin-bottom: 15px;
}

.btn-face,
.btn-google {
  font-size: 16px;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  transition: all 0.4s;
}

.btn-face {
  color: #555555;
}

.btn-google {
  color: #555555;
}

.btn-google img,
.btn-face img {
  width: 16px;
  margin-right: 12px;
  padding-bottom: 1px;
}

.btn-face:hover,
.btn-google:hover {
  border-color: #ff3d00;
  color: #000;
}

@media (max-width: 767px) {
  .signup-bg {
    background-color: #f2f2f2;
    height: 100vh;
  }
  .p-t-9 {
    padding-top: 90px !important;
  }

  .bg-grey {
    background: #f9f9f9;
  }

  .form {
    margin: 0 auto;
  }

  .form form {
    border: transparent;
  }

  .logo-none {
    display: none;
  }

  .form .logo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 15px 25px 15px 25px;
  }

  .form .form-content {
    padding: 25px;
  }

  .form .form-content .radio-btn {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
  }

  .mobile-nav-toggle {
    background: #fff;
    display: block;
    position: unset;
  }

  .mobile-nav-toggle i {
    display: block;
    text-align: left;
    margin: 9px 10px 9px;
  }

  .form .form-content .form-group .term-Con span {
    color: #ff3d00;
  }

  .form-layout {
    padding-top: 30px;
  }

  .form-layout .logo {
    border-bottom: transparent;
  }

  .form-layout .form-content {
    padding: 10px 25px 25px 25px;
  }

  .other-btn {
    border-top: 0.5px solid rgba(0, 0, 0, 0.12);
    margin: 8px 25px;
  }

  .form-layout .other-btn .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }

  .form-layout .form-content .radio-btn {
    box-shadow: inherit;
  }

  .appLive .form-layout {
    padding-top: 80px;
  }

  .passRest .form {
    text-align: left;
  }

  .passRest .form .form-content h4 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.18px;
  }
}

@media (max-width: 991px) {
  .cross {
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.12);*/
    /* height: 60px; */
  }

  .mobile-nav-toggle {
    background: #fff;
    display: block;
    position: unset;
  }

  .mobile-nav-toggle i {
    display: block;
    text-align: left;
    margin: 10px 10px 10px;
  }

  .appLive .mobile-nav-toggle {
    background: #fff;
    display: block;
    position: fixed;
  }

  .appLive .mobile-nav-toggle i {
    text-align: left;
    margin: 21px 10px 10px;
  }
}

