.bg-grey {
  background: #fff;
}

.form-check-label {
  align-items: center;
  justify-content: center;
  display: flex;
}

.mobile-nav-toggle i {
  display: none;
}

.form-control {
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 54px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-left: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

/*Sign up form*/
.cross {
  background: #fff;
}

.form {
  max-width: 506px;
  margin: 50px auto;
}

.form form {
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.form .logo {
  width: 100%;
  padding: 40px 40px 0 40px;
}

.form .form-content {
  padding: 20px 40px 40px 40px;
}

.form .form-content h4 {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 28px;
  font-weight: 500;
}

.form .form-content .form-group {
  margin-bottom: 20px;
  transition: all 0.5s;
  position: relative;
}

.form .form-content .radio-btn {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 30px 15px 30px 25px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.form .form-content .form-check-input {
  position: inherit;
  margin-top: 0;
  margin-right: 15px;
  margin-left: 0;
}

.form .form-content .form-group a {
  color: #ff3d00;
  display: block;
  margin-top: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 16px;
}

.form .form-content .form-group i {
  position: absolute;
  top: 17px;
  right: 30px;
  font-weight: 600;
}

.form .form-content .form-group .term-Con {
  text-transform: capitalize;
  color: #000;
}

.form .form-content .form-group label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
}

.form .form-content .form-group input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin-right: 8px;
  border: 2px solid #000;
}

.form .form-content .form-check {
  padding-left: 0;
}

.form .form-content .form-check label {
  justify-content: flex-start;
  /* text-transform: capitalize; */
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.other-btn {
  padding: 8px 0;
}

.other-btn .btn {
  text-transform: lowercase;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
}

.form-layout .form-content .ag-name {
  background: #f2f2f2;
  padding: 6px 8px;
  border-radius: 5px;
}

.form-layout .form-content span b {
  display: block;
  text-transform: capitalize;
}

select {
  background: #f0f3fb;
  border: 1px solid #f0f3fb;
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  color: #3f3f3f;
  appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:after {
  font-family: FontAwesome;
  content: "\f0dd";
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 30px;
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
}

select::-ms-expand {
  display: none;
}

.passRest .form {
  text-align: center;
  max-width: 700px;
}

.passRest .form .form-content h4 {
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.18px;
}

.appLive {
  padding-top: 40px;
}

.appLive .btn {
  width: 100%;
}

.edit .form .form-group .edit-label {
  position: absolute;
  top: -11px;
  left: 22px;
  font-size: 14px;
  padding: 0 6px;
  background: #fff;
  text-transform: capitalize;
}

.form .form-content .counter {
  height: 80px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
}

.form .form-content .counter span:first-child {
  width: 20%;
  display: inline-block;
}

.form .form-content .counter span:last-child {
  width: 80%;
  display: inline-block;
}

.form .form-content .counter i {
  left: 20px;
  top: 20px;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.38);
  font-weight: 400;
  position: inherit;
}

.form .form-content .counter input {
  width: 100%;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  padding: 13px 11px 13px 19px;
  font-size: 22px;
  line-height: 26px;
  font-family: "Roboto", sans-serif !important;
  color: rgba(0, 0, 0, 0.38);
}
.form .form-content .counter input:focus,
.form .form-content .counter input:active {
  border: #ff3d00;
  box-shadow: 0 0 0 0.2rem rgb(255 61 0 / 25%) !important;
  border: none;
}

.fillter .form .form-content {
  padding: 25px 25px 60px;
}

.fillter .form .form-content .head {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.fillter .form .form-content .head .title {
  width: 90%;
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: capitalize;
  text-align: center;
  font-weight: 500;
}

.fillter .form .form-content .head .cross {
  width: 10%;
}

.fillter .form .form-content .head .cross button {
  border: none;
  background: transparent;
}

.fillter .form .form-content .head .cross button i {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.fillter .form .form-content .fil-tab {
  width: 100%;
  margin-bottom: 20px;
}

.fillter .form .form-content .fil-tab ul {
  display: flex;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.fil-active {
  background-color: #fbe9e7;
  color: #ff3d00 !important;
}
.fil-active > a {
  color: #ff3d00 !important;
}

.fillter .form .form-content .fil-tab ul li {
  padding: 10px 6px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fillter .form .form-content .fil-tab ul li a {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.fillter .form .form-content .fil-tab ul li:nth-child(1) {
  width: 20%;
}

.fillter .form .form-content .fil-tab ul li:nth-child(2) {
  width: 40%;
}

.fillter .form .form-content .fil-tab ul li:nth-child(3) {
  border-right: none;
  width: 40%;
}

.fillter .form .form-content .fil-tab ul li:hover {
  background: #fbe9e7;
}

.fillter .form .form-content .fil-tab ul li:hover a {
  color: #ff3d00;
}

.fillter .form .form-content .form-group {
  margin-bottom: 10px;
}

.fillter .form .form-content .form-group .form-control {
  height: 40px;
  font-size: 14px;
  /* padding-left: 8px; */
  line-height: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding: 7px 0 7px 8px;
}

.fillter .form .form-content .form-group .select-wrapper:after {
  top: 4px;
  right: 15px;
}

.fillter .form .form-content .it-list {
  margin-bottom: 20px;
}

.fillter .form .form-content .it-list ul {
  display: flex;
  flex-wrap: wrap;
}

.fillter .form .form-content .it-list ul li {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 9px;
  margin: 5px 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 4px;
}

.fillter .form .form-content .it-list ul li:hover {
  background: #fbe9e7;
  color: #ff3d00;
  border: 1px solid #ff3d00;
}

.fillter .form .bot-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0px;
  background: #fff;
  width: 420px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.fillter .form .bot-btn-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0px;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
}

.fillter .form .bot-btn button {
  width: 100%;
}

.fillter .form .bot-btn-mobile button {
  width: 100%;
}

.div-radio:hover {
  cursor: pointer;
}

.popup .form-content h4 {
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 24px;
  font-weight: 500;
}

.popup .form-content {
  padding: 15px !important;
}

/*--Button sign in with---*/
.oth-login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.oth-login a {
  width: 100%;
  margin-bottom: 15px;
}

.btn-face,
.btn-google {
  font-size: 16px;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  transition: all 0.4s;
}

.btn-face {
  color: #555555;
}

.btn-google {
  color: #555555;
}

.btn-google img,
.btn-face img {
  width: 16px;
  margin-right: 12px;
  padding-bottom: 1px;
}

.btn-face:hover,
.btn-google:hover {
  border-color: #ff3d00;
  color: #000;
}

@media (max-width: 767px) {
  .signup-bg {
    background-color: #f2f2f2;
    height: 100vh;
  }
  .p-t-9 {
    padding-top: 90px !important;
  }

  .bg-grey {
    background: #f9f9f9;
  }

  .form {
    margin: 0 auto;
  }

  .form form {
    border: transparent;
  }

  .logo-none {
    display: none;
  }

  .form .logo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 15px 25px 15px 25px;
  }

  .form .form-content {
    padding: 25px;
  }

  .form .form-content .radio-btn {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
  }

  .mobile-nav-toggle {
    background: #fff;
    display: block;
    position: unset;
  }

  .mobile-nav-toggle i {
    display: block;
    text-align: left;
    margin: 9px 10px 9px;
  }

  .form .form-content .form-group .term-Con span {
    color: #ff3d00;
  }

  .form-layout {
    padding-top: 30px;
  }

  .form-layout .logo {
    border-bottom: transparent;
  }

  .form-layout .form-content {
    padding: 10px 25px 25px 25px;
  }

  .other-btn {
    border-top: 0.5px solid rgba(0, 0, 0, 0.12);
    margin: 8px 25px;
  }

  .form-layout .other-btn .col-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center !important;
  }

  .form-layout .form-content .radio-btn {
    box-shadow: inherit;
  }

  .appLive .form-layout {
    padding-top: 80px;
  }

  .passRest .form {
    text-align: left;
  }

  .passRest .form .form-content h4 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.18px;
  }
}

@media (max-width: 991px) {
  .cross {
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.12);*/
    /* height: 60px; */
  }

  .mobile-nav-toggle {
    background: #fff;
    display: block;
    position: unset;
  }

  .mobile-nav-toggle i {
    display: block;
    text-align: left;
    margin: 10px 10px 10px;
  }

  .appLive .mobile-nav-toggle {
    background: #fff;
    display: block;
    position: fixed;
  }

  .appLive .mobile-nav-toggle i {
    text-align: left;
    margin: 21px 10px 10px;
  }
}
