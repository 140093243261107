/*nero Section*/
.nero {width: 100%;position: relative;background: url("../images/hero-banner.png") center bottom no-repeat;background-size: cover;padding: 140px 0 60px 0;background-position: top; transition: all 0.5s;}
.nero .nero-info {width: 70%;}
.nero .nero-info h1 {color: #fff;margin-bottom: 15px;font-size: 62px;font-weight: 700; line-height: 73px;}
.nero .nero-info p{color: #fff;margin-bottom: 8px;}
.nero .nero-info .btn-get-started {font-family: "Roboto", sans-serif;font-size: 14px;font-weight: 600;letter-spacing: 1px;  display: inline-block;padding: 10px 32px;border-radius: 50px;transition: 0.5s;margin: 0 20px 20px 0;color: #fff;}
.nero .nero-info .btn-get-started {background: #007bff;border: 2px solid #007bff;color: #fff;}
.nero .nero-info .btn-get-started:hover {background: none;border-color: #fff;color: #fff;}

/*Sections*/
section { overflow: hidden; transition: all 0.5s;}
.section-header h3 {font-size: 36px;color: #283d50;text-align: center;font-weight: 500;position: relative;}
.section-header p {text-align: center;margin: auto;font-size: 15px;padding-bottom: 60px;color: #556877;width: 50%;}

/* Content Section*/
.sec-Cont {padding: 40px 0 40px 0; transition: all 0.5s;}
.sec-Cont .card-Cont{display: flex;justify-content: center;align-items: center;padding: 0 30px;}
.sec-Cont .card-Cont .content {padding: 8px 0;}
.sec-Cont .card-Cont .content .title {color: #333;font-weight: 500;font-size: 34px;line-height: 42px;margin-bottom: 7px;}
.sec-Cont .card-Cont .content p {line-height: 20px;margin-bottom: 10px;padding: 5px 0;}
.sec-Cont .card-Cont .content p:last-child {margin-bottom: 0;}
.sec-Cont .cont-extra {padding-top: 60px;}
.sec-Cont .cont-extra h4 {font-weight: 600;font-size: 24px;}

/*get-started*/
.get-started {padding: 60px 0;background: url("../images/get-started.png") center bottom no-repeat;background-size: cover;background-position: center; transition: all 0.5s;}
.get-started .card-Cont .content .title {color: #333;font-weight: 700;font-size: 34px;line-height: 42px;margin-bottom: 20px;}

/* Contact Section*/
.contact .cont-form{background: #fff;padding: 20px 50px ;max-width: 630px;margin: 0 auto;border-radius: 5px; transition: all 0.5s;}
.contact h3 {font-size: 28px;line-height: 33px;font-weight: 700;text-align: center;margin-bottom: 20px;}
.contact .form input, .contact .form textarea {border-radius: 4px;box-shadow: none;font-size: 16px;line-height: 24px;letter-spacing: 1px;}
.contact .form input {padding: 20px 15px;height: 54px;}
.contact .form textarea {padding: 12px 15px;}
.contact .form .btn{padding: 0 40px;}
.primary-label{color:#FF3D00;}

@media (max-width: 574px) {
    .nero {padding: 100px 0 20px 0;}
}


@media (max-width: 767px) {
  .bg-grey{background: #ffffff;}
  .nero .nero-info {width: 100%;text-align: left;padding: 18px 15px;}
  .nero .nero-info h1 {font-size: 30px;line-height: 35px;margin-bottom: 10px;}
  .nero .nero-info p{font-size: 18px;}
  .sec-Cont .card-Cont{padding: 0 10px;}
  .section-header p {width: 100%;}
  .sec-Cont{padding: 40px 15px 0 15px;}
  .sec-Cont .card-Cont{padding: 0 10px;}
  .sec-Cont .card-Cont .content .title{font-size: 24px; line-height: 28px; margin-bottom: 0;}
  .get-started {margin-top: 40px;}
  .get-started .card-Cont .content .title {font-size: 28px;line-height: 34px;margin-bottom: 20px;}
  .get-started .card-Cont .content .title span{color: #FF3D00;}
  .contact .cont-form{padding: 0 8px 35px 8px;}
  
}

@media (max-width: 991px) {
  .navbar-custom .logo {margin-left: 40px;width: inherit;}

}





