.accInfo .new-nav .sec-Cont {
  width: 90%;
}

.accInfo .new-nav .sec-Cont .myAcc {
  max-width: 828px;
  margin: 30px auto;
  background: #fff;
  border-radius: 5px;
  display: flex;
  height: 100vh;
}

.accInfo .new-nav .sec-Cont .myAcc .content {
  width: 50%;
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .content .tab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .tab ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .content .tab ul li {
  width: 100%;
  text-align: center;
  padding: 12px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
}

.accInfo .new-nav .sec-Cont .myAcc .content .tab ul li.active {
  color: #FF3D00;
  border-bottom: 2px solid #FF3D00;
}

.accInfo .new-nav .sec-Cont .myAcc .content .search-txt {
  padding: 0px 15px 10px 15px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.accInfo .new-nav .sec-Cont .myAcc .content .search-txt input[type="text"] {
  padding-left: 40px;
  height: 48px;
  font-size: 14px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .search-txt i {
  position: absolute;
  top: 14px;
  left: 25px;
  color: rgba(0, 0, 0, 0.6);
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .naME {
  width: 25%;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .naME b {
  background: #FBE9E7;
  color: #FF3D00;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  border-radius: 50%;
  padding: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .deTail {
  width: 80%;
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .deTail h4 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
}

.accInfo .new-nav .sec-Cont .myAcc .content .blog .deTail span {
  position: absolute;
  top: 0;
  right: 5px;
  color: rgba(0, 0, 0, 0.38);
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
}

.accInfo .new-nav .sec-Cont .myAcc .content .newMass {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .newMass button {
  border-radius: 28px;
  height: 35px;
  padding: 7px 15px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col {
  display: block;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col {
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 20px;
  display: block;
  width: 100%;
  text-align: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .title .back-icon {
  padding-right: 20px;
  display: none;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .title .more-icon {
  display: none;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massList {
  padding: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massList .massDet {
  background: #F2F2F2;
  border-radius: 4px;
  width: 70%;
  padding: 20px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massList .mechat{margin-top: 15px; margin-left: auto; background: #FBE9E7; color: #000;}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 8px 8px 8px;
  background: #fff;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot ul li {
  width: 50%;
  padding: 8px 4px 8px 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 9px 5px;
  border-radius: 4px;
  color: #FF3D00;
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  box-sizing: border-box;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType div:first-child i {
  font-size: 22px;
  font-weight: 700;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType div:first-child {
  width: 10%;
  text-align: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType div:last-child {
  width: 90%;
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .massBot .massType div:last-child i {
  position: absolute;
  right: 15px;
  top: 10px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .pfile {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  margin: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accInfo .new-nav .sec-Cont .myAcc .content .pfile .naME {
  width: 30%;
  margin-right: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .pfile .naME b {
  background: #FBE9E7;
  color: #FF3D00;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  border-radius: 50%;
  padding: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li {
  display: block;
  width: 100%;
  padding: 12px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 400;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li a i {
  padding-right: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li:after {
  content: " ";
  border: solid #000000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 20px;
  right: 21px;
  transform: rotate(-45deg);
}

.accInfo .new-nav .sec-Cont .myAcc .content .accList ul li.active {
  background: #FBE9E7;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .pfile {
  padding: 25px;
  margin-right: 0;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .pfile .naME {
  position: relative;
  display: block;
  margin: 0;
  width: 30%;
  text-align: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .pfile .naME b {
  font-size: 48px;
}

.edit {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #FF3D00;
  border-radius: 25px;
  padding: 5px;
  width: 35px;
  height: 35px;
  text-align: center;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .accDet li {
  padding: 15px 25px 15px 40px !important;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .accDet li span {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .accDet ul li:after {
  top: 35px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .accDet li .agn-stats {
  background: #005338;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  /* text-align: right; */
  position: absolute;
  right: 45px;
  top: 25px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.accInfo .new-nav .sec-Cont .myAcc .content .accDet ul li a i {
  padding-right: 5px;
}


.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardDet {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardDet .cardNo {
  width: 90%;
  margin-left: 15px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardDet .cardNo span {
  display: block;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardEdit {
  padding: 30px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardEdit .form-control {
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 54px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-left: 20px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardEdit .form-group {
  margin-bottom: 25px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .cardEdit .form-group .edit-label {
  position: absolute;
  top: -11px;
  left: 22px;
  font-size: 14px;
  padding: 0 6px;
  background: #fff;
  text-transform: capitalize;
  opacity: 0.6;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .notiList ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 15px 20px;
  position: relative;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .notiList ul li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .notiList ul li span {
  position: absolute;
  right: 20px;
  top: 12px;
}


.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 17px;
  border-radius: 20px;
  background: #dce4e2;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
}

.switch::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0px;
  width: 25px;
  height: 25px;
  background: #acb9b5;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

input:checked+.switch {
  background: #54bf9c;
}

input:checked+.switch::before {
  left: 25px;
  background: #005338;
}

input:checked+.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .searchCode ul {
  display: flex;
  padding: 0 20px;
  flex-wrap: wrap;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .searchCode ul li {
  /* width: 100%; */
  margin-right: 10px;
  margin-bottom: 16px;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .searchCode ul li a {
  background: rgba(33, 33, 33, 0.08);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding: 6px 15px;
  border-radius: 15px;
  opacity: 0.6;
}

.accInfo .new-nav .sec-Cont .myAcc .rgt-col .bAgent ul li a {
  background: #FBE9E7;
}

.review .sec-Cont .overley {
  background: #000;
  opacity: 0.4;
  display: block;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.review .sec-Cont .form {
  max-width: 560px;
  margin: 0 auto;
}

.review .sec-Cont .form-content {
  padding: 20px 0;
}

.review .sec-Cont .form-content .title {
  position: relative;
  margin-bottom: 20px;
  padding: 10px 30px;
}

.review .sec-Cont .form-content .title span {
  position: absolute;
  right: 30px;
}

.review .sec-Cont .form-content .pfile {
  padding: 15px 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review .sec-Cont .form-content .pfile .naME {
  width: 30%;
  margin-right: 15px;
}

.review .sec-Cont .form-content .pfile b {
  background: #FBE9E7;
  color: #FF3D00;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  border-radius: 50%;
  padding: 15px;
}

.review .sec-Cont .form-content .reViewSec {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  margin: 20px 30px;
}

.review .sec-Cont .form-content .reViewSec div {
  display: flex;
  align-items: center;
}

.review .sec-Cont .form-content .reViewSec div .name {
  margin-left: 15px;
  font-weight: 500;
}

.review .sec-Cont .form-content .reViewSec div .reStar {
  margin-left: auto;
}

@media (max-width: 767px) {}

@media (max-width: 991px) {
  .accInfo .new-nav .sec-Cont {
    width: 100%;
    padding: 0;
  }

  .accInfo .new-nav .sec-Cont .myAcc {
    display: inherit;
  }

  .accInfo .new-nav .sec-Cont .myAcc .content {
    width: 100%;
    height: 100vh;
  }

  .accInfo .new-nav .sec-Cont .myAcc .rgt-col {
    display: none;
  }

  .accInfo .new-nav .sec-Cont .myAcc .rgt-col .title {
    display: inline-flex;
  }

  .accInfo .new-nav .sec-Cont .myAcc .rgt-col .title .back-icon {
    display: block;
  }

  .accInfo .new-nav .sec-Cont .myAcc .rgt-col .title .more-icon {
    display: block;
    position: absolute;
    right: 25px;
  }

  .review .sec-Cont .form-content {
    padding: 0;
  }

  .review .sec-Cont .form-content .title {
    padding: 12px 15px;
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .review .sec-Cont .form-content .pfile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .review .sec-Cont .form-content .reViewSec {
    margin: 20px;
  }

  .review .sec-Cont .overley {
    background: #fff;
  }

  .mpage {
    display: block !important;
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 99999;
  }

  .accInfo .new-nav .sec-Cont .myAcc .border-right {
    border-right: transparent !important;
  }


}

.ScrollStyle
{
    max-height: 570px;
    overflow-y: scroll;
}